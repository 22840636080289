export const ComponentsActionStateLabels = [
  {
    label: "All",
    value: [
      "component.status.active",
      "component.status.inactive",
    ],
  },
  { label: "Active", value: "component.status.active" },
  { label: "Inactive", value: "component.status.inactive" },
];
